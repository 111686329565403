import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { environment } from 'src/environments/environment';
import { BillingConfirmSetup, BillingInitSetup, BillingSubscription } from '../model/billing-settings.model';

const APIURL = `${environment.apiUrl}/cmp/`;

@Injectable({
    providedIn: 'root'
})
export class BillingSettingsApiService {

    constructor(
        private http: HttpClient,
    ) { }

    async initSetupApi() {
        return await lastValueFrom(this.http
            .post<ApiResponse<BillingInitSetup>>(
                APIURL + 'BillingSettings/PaymentMethods/InitSetup',
                {}
            ));
    }

    async confirmSetup(setupIntentId: string, setAsDefault: boolean) {
        return await lastValueFrom(this.http
            .post<ApiResponse<BillingConfirmSetup>>(
                APIURL + 'BillingSettings/PaymentMethods/ConfirmSetup',
                {
                    setupIntentId: setupIntentId,
                    setAsDefault: setAsDefault
                }
            ));
    }

    async subscription() {
        return await lastValueFrom(this.http
            .get<ApiResponse<BillingSubscription>>(
                APIURL + 'BillingSettings/Subscription'
            ));
    }

}
